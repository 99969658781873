import React from 'react';
import { Button } from "@/components/ui/button";
import { Mic, MessageSquare, Globe } from "lucide-react";

const Product = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <main className="container mx-auto px-6 py-12">
        <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight text-center">
          Speaked Product
        </h1>
        <p className="text-xl mb-12 text-gray-400 max-w-2xl mx-auto text-center">
          Discover the power of AI-driven character interactions with Speaked.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          {[
            {
              icon: <Mic className="w-12 h-12 mb-4" />,
              title: "Voice Cloning",
              description: "Create perfect voice replicas of your characters, maintaining authenticity and engagement."
            },
            {
              icon: <MessageSquare className="w-12 h-12 mb-4" />,
              title: "Interactive Conversations",
              description: "Enable dynamic, personalized interactions between users and your AI characters."
            },
            {
              icon: <Globe className="w-12 h-12 mb-4" />,
              title: "Global Reach",
              description: "Connect with audiences worldwide through AI-powered character experiences."
            }
          ].map((feature, index) => (
            <div key={index} className="bg-gray-900 p-6 rounded-lg text-center">
              <div className="flex justify-center">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="text-center mt-12">
          <Button variant="outline" className="bg-gray-800 text-white hover:bg-gray-700 text-lg px-8 py-3">
            Learn More
          </Button>
        </div>
      </main>
    </div>
  );
};

export default Product;
import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Mic, MessageSquare, Globe, Users, DollarSign, ShieldCheck } from "lucide-react";

export default function SpeakedLanding() {
  const [titleState, setTitleState] = useState('initial');

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fadeIn');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('section, h1, p, .feature-grid > div, img, button').forEach((el) => {
      observer.observe(el);
    });

    // Trigger the title animation sequence
    setTimeout(() => setTitleState('strikethrough'), 1000);
    setTimeout(() => setTitleState('replace'), 2500);

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      icon: <Mic className="w-6 h-6" />,
      title: "Character Training",
      description: "Train models that embody your characters' behaviors, voices, and personalities."
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      title: "Interactive Applications",
      description: "Enable new interactive applications in education, gaming, and customer service."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Multi-Platform Deployment",
      description: "Deploy your characters across digital and real-world touchpoints."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Third-Party Licensing",
      description: "Confidently license your models to third parties after rigorous testing and validation."
    },
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: "New Revenue Streams",
      description: "Tap into the $147.6 billion character licensing industry with interactive AI."
    },
    {
      icon: <ShieldCheck className="w-6 h-6" />,
      title: "Legal Compliance",
      description: "Ensure adherence to all legal requirements and protect against IP misuse."
    }
  ];

  return (
    <div className="bg-gradient-to-b from-black to-gray-900 text-white min-h-screen">
      <main className="container mx-auto px-4 py-16 space-y-24">
        <section className="text-center space-y-8">
          <h1 className="text-5xl md:text-7xl font-bold leading-tight opacity-0 bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-600">
            Monetize Your Characters with AI
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto opacity-0">
            Create AI-powered versions of your characters and personalities.
          </p>
          <Button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-3 px-6 rounded-full transition duration-300 opacity-0">
            Get Started
          </Button>
          <img 
            src="https://i.imgur.com/D6PbBJx.png" 
            alt="AI Character" 
            className="mx-auto max-w-full h-auto opacity-0 rounded-lg shadow-2xl" 
            style={{ maxWidth: '60%' }}
          />
        </section>

        <section className="opacity-0">
          <h2 className="text-3xl font-bold mb-6 text-center">How it works</h2>
          <p className="text-lg mb-12 text-gray-300 max-w-2xl mx-auto text-center">
            Speaked enables companies to create AI-powered versions of their characters, 
            opening new engagement opportunities across multiple touchpoints.
          </p>
          <div className="feature-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 bg-opacity-50 p-6 rounded-xl opacity-0 transition-all duration-300 hover:bg-opacity-70 hover:shadow-lg">
                <div className="flex items-center mb-4">
                  <div className="mr-3 text-blue-400">{feature.icon}</div>
                  <h3 className="text-lg font-semibold">{feature.title}</h3>
                </div>
                <p className="text-sm text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </div>
  );
}
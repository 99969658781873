import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { Button } from "@/components/ui/button";
import SpeakedLanding from './SpeakedLanding';
import Product from './Product';
import Features from './Features';
import Pricing from './Pricing';
import Test from './test';

const Header: React.FC = () => (
  <header className="bg-black text-white p-4">
    <div className="container mx-auto flex justify-between items-center">
      <Link to="/" className="text-2xl font-bold">Speaked</Link>
      {/* <nav>
        <ul className="flex space-x-6">
          <li><Link to="/product" className="hover:text-gray-300">Product</Link></li>
          <li><Link to="/features" className="hover:text-gray-300">Features</Link></li>
          <li><Link to="/pricing" className="hover:text-gray-300">Pricing</Link></li>
        </ul>
      </nav> */}
      <Link to="https://forms.gle/F6Dgb5X2W8E4u2Tq6" className="inline-block" target="_blank" rel="noopener noreferrer">
        <Button variant="outline" className="bg-gray-800 text-white hover:bg-gray-700">
          Get Started
        </Button>
      </Link>
    </div>
  </header>
);

const Footer: React.FC = () => (
  <footer className="bg-gray-900 text-gray-400 py-8">
    <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row justify-between items-center">
        <div className="mb-4 md:mb-0">
          © 2024 Speaked. All rights reserved.
        </div>
        {/* <nav>
          <ul className="flex space-x-4">
            <li><button className="hover:text-white">Privacy Policy</button></li>
            <li><button className="hover:text-white">Terms of Service</button></li>
            <li><button className="hover:text-white">Contact Us</button></li>
            <li><button className="hover:text-white">Sitemap</button></li>
          </ul>
        </nav> */}
      </div>
    </div>
  </footer>
);

const App: React.FC = () => {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<SpeakedLanding />} />
            <Route path="/product" element={<Product />} />
            <Route path="/features" element={<Features />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/test" element={<Test />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button";
import { Mic, MessageSquare, Globe, Users, DollarSign, ShieldCheck } from "lucide-react";
import styles from './SpeakedLanding.module.css';
import ScrollingCategoryList from './Scrolling';

export default function SpeakedLanding() {
  const [titleState, setTitleState] = useState('initial');

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-fadeIn');
        }
      });
    }, { threshold: 0.1 });

    document.querySelectorAll('section, h1, p, .feature-grid > div, img, button').forEach((el) => {
      observer.observe(el);
    });

    // Adjusted timing for the animation sequence
    setTimeout(() => setTitleState('strikethrough'), 1000);
    setTimeout(() => setTitleState('replace'), 2000);

    return () => observer.disconnect();
  }, []);

  const features = [
    {
      icon: <Mic className="w-6 h-6" />,
      title: "Character Training",
      description: "Train models that embody your characters' behaviors, voices, and personalities."
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      title: "Interactive Applications",
      description: "Enable new interactive applications in education, gaming, and customer service."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Multi-Platform Deployment",
      description: "Deploy your characters across digital and real-world touchpoints."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Third-Party Licensing",
      description: "Confidently license your models to third parties after rigorous testing and validation."
    },
    {
      icon: <DollarSign className="w-6 h-6" />,
      title: "New Revenue Streams",
      description: "Tap into the $147.6 billion character licensing industry with interactive AI."
    },
    {
      icon: <ShieldCheck className="w-6 h-6" />,
      title: "Legal Compliance",
      description: "Ensure adherence to all legal requirements and protect against IP misuse."
    }
  ];

  return (
    <div className="bg-gradient-to-b from-black to-gray-900 text-white min-h-screen">
      <main className="container mx-auto px-4 py-16 space-y-24">
        <section className="text-center space-y-8">
          <h1 className="text-5xl md:text-7xl font-bold leading-tight">
            <span className={`relative ${styles.titleWord}`}>
              <span className={`${styles[titleState]} ${styles.gradientText}`}>
                Intellectual
              </span>
              <span className={`absolute left-0 ${styles.replaceText} ${styles.gradientText}`}>
                Interactive
              </span>
            </span>
            {titleState !== 'replace' && '\u00A0'}
            <span className={styles.titleWord}>Property</span>
          </h1>

          <p className="text-xl text-gray-300 max-w-2xl mx-auto opacity-0">
            Speaked helps you deploy your characters across all industries. <br></br>We handle the tech, you handle the experience.
          </p>
        </section>

        <section className="opacity-0">
          {/* <h2 className="text-3xl font-bold mb-6 text-center">How it works</h2>
          <p className="text-lg mb-12 text-gray-300 max-w-2xl mx-auto text-center">
            Speaked enables companies to create AI-powered versions of their characters, 
            opening new engagement opportunities across multiple touchpoints.
          </p> */}
          <div className="feature-grid grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <div key={index} className="bg-gray-800 bg-opacity-50 p-6 rounded-xl opacity-0 transition-all duration-300 hover:bg-opacity-70 hover:shadow-lg">
                <div className="flex items-center mb-4">
                  <div className="mr-3 text-blue-400">{feature.icon}</div>
                  <h3 className="text-lg font-semibold">{feature.title}</h3>
                </div>
                <p className="text-sm text-gray-300">{feature.description}</p>
              </div>
            ))}
          </div>
        </section>
        {/* <ScrollingCategoryList /> */}
        <section className="text-center py-16 bg-gray-900">
          <div className="container mx-auto px-4">
            <h2 className="text-sm uppercase tracking-wide text-gray-400 mb-4">
              Get started
            </h2>
            <h3 className="text-4xl font-bold mb-4">
              Contact us for more information
            </h3>
            <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
              Reach out to learn how we can help.
            </p>
            <Button 
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-6 rounded-lg text-lg"
              onClick={() => window.open('https://docs.google.com/forms/d/e/1FAIpQLScM0ak17IZ_C_2iNqcNiK8xNPHEN262s8NfWpG0ReFPesjTAw/viewform', '_blank')}
            >
              Contact Us
            </Button>
          </div>
        </section>
      </main>
    </div>
  );
}
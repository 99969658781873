import React from 'react';
import { Button } from "@/components/ui/button";
import { Check } from "lucide-react";

interface PricingTierProps {
  title: string;
  price: number;
  features: string[];
  recommended?: boolean;
}

const PricingTier: React.FC<PricingTierProps> = ({ title, price, features, recommended = false }) => (
  <div className={`bg-gray-900 p-8 rounded-lg ${recommended ? 'border-2 border-blue-500' : ''}`}>
    {recommended && (
      <div className="text-blue-500 font-semibold mb-4">Recommended</div>
    )}
    <h3 className="text-2xl font-bold mb-4">{title}</h3>
    <div className="text-4xl font-bold mb-6">${price}<span className="text-xl text-gray-400">/month</span></div>
    <ul className="mb-8">
      {features.map((feature, index) => (
        <li key={index} className="flex items-center mb-2">
          <Check className="w-5 h-5 mr-2 text-green-500" />
          <span>{feature}</span>
        </li>
      ))}
    </ul>
    <Button variant="outline" className="w-full bg-gray-800 text-white hover:bg-gray-700">
      Choose Plan
    </Button>
  </div>
);

const Pricing: React.FC = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <main className="container mx-auto px-6 py-12">
        <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight text-center">
          Pricing Plans
        </h1>
        <p className="text-xl mb-12 text-gray-400 max-w-2xl mx-auto text-center">
          Choose the perfect plan for your AI character needs.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          <PricingTier
            title="Starter"
            price={49}
            features={[
              "1 AI character",
              "1,000 interactions/month",
              "Basic analytics",
              "Email support"
            ]}
          />
          <PricingTier
            title="Professional"
            price={99}
            features={[
              "5 AI characters",
              "10,000 interactions/month",
              "Advanced analytics",
              "Priority email support",
              "API access"
            ]}
            recommended={true}
          />
          <PricingTier
            title="Enterprise"
            price={299}
            features={[
              "Unlimited AI characters",
              "Unlimited interactions",
              "Custom analytics",
              "24/7 phone & email support",
              "Dedicated account manager",
              "Custom integrations"
            ]}
          />
        </div>

        <div className="mt-16 text-center">
          <h2 className="text-2xl font-bold mb-4">Need a custom solution?</h2>
          <p className="text-gray-400 mb-8">Contact us for a tailored plan that fits your specific requirements.</p>
          <Button variant="outline" className="bg-gray-800 text-white hover:bg-gray-700">
            Contact Sales
          </Button>
        </div>
      </main>
    </div>
  );
};

export default Pricing;
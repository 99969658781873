import React from 'react';
import { Users, Clock, ShieldCheck } from "lucide-react";

const Features = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <main className="container mx-auto px-6 py-12">
        <h1 className="text-5xl md:text-7xl font-bold mb-6 leading-tight text-center">
          Speaked Features
        </h1>
        <p className="text-xl mb-12 text-gray-400 max-w-2xl mx-auto text-center">
          Explore the cutting-edge features that make Speaked the ultimate platform for AI character creation.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mt-12">
          {[
            {
              icon: <Users className="w-12 h-12 mb-4" />,
              title: "Personalized Conversations",
              description: "Tailor interactions based on user preferences and behavior for a unique experience."
            },
            {
              icon: <Clock className="w-12 h-12 mb-4" />,
              title: "Real-time Responses",
              description: "Engage users with instant, context-aware replies from your AI characters."
            },
            {
              icon: <ShieldCheck className="w-12 h-12 mb-4" />,
              title: "Secure & Compliant",
              description: "Ensure legal compliance and data security with our robust platform."
            }
          ].map((feature, index) => (
            <div key={index} className="bg-gray-900 p-6 rounded-lg text-center">
              <div className="flex justify-center">{feature.icon}</div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </div>
          ))}
        </div>

        <div className="mt-16">
          <h2 className="text-3xl font-bold mb-8 text-center">Additional Features</h2>
          <ul className="list-disc list-inside text-gray-400 max-w-2xl mx-auto">
            <li className="mb-4">Advanced natural language processing for human-like interactions</li>
            <li className="mb-4">Multi-language support for global audience engagement</li>
            <li className="mb-4">Customizable character traits and knowledge bases</li>
            <li className="mb-4">Analytics dashboard for tracking user engagement and interactions</li>
            <li className="mb-4">Seamless integration with existing platforms and websites</li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Features;